<script>
    export default {
        page: {
            title: "Version Page",
        },
        computed: {
            version() {
                return process.env.VUE_APP_VERSION;
            },
        },
    };
</script>

<template>
    <div>
        {{version}}
    </div>
</template>
